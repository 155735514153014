import(/* webpackMode: "eager" */ "/app/app/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/app/styles/markdown.scss");
;
import(/* webpackMode: "eager" */ "/app/app/styles/highlight.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/inter/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
